// extracted by mini-css-extract-plugin
export var bottom = "contact-module--bottom--95a46";
export var card = "contact-module--card--75709";
export var cardHeader = "contact-module--cardHeader--88886";
export var contact = "contact-module--contact--d1e60";
export var contactHeader = "contact-module--contactHeader--e4caa";
export var input = "contact-module--input--5c565";
export var label = "contact-module--label--df54d";
export var left = "contact-module--left--7a382";
export var linkBlue = "contact-module--linkBlue--47277";
export var linkGradient = "contact-module--linkGradient--2aee1";
export var linkGreen = "contact-module--linkGreen--6d520";
export var right = "contact-module--right--4f77c";
export var social = "contact-module--social--e67fd";
export var socialCards = "contact-module--socialCards--353d5";
export var textArea = "contact-module--textArea--9e7ce";
export var top = "contact-module--top--1e8cd";