import React from "react"
import { Layout } from "../components"
import Container from "../components/Container"
import * as styles from "../styles/contact.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const contact = () => {
  return (
    <Layout>
      <div>
        <Container>
          <section className={styles.contact}>
            <div className={styles.top}>
              <div className={styles.left}>
                <h1 className={styles.contactHeader}>
                  Love to hear from you,
                  <br />
                  <span>Get in touch</span> 👋
                </h1>
                <form
                  name="Contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="Contact" />
                  <p>
                    <label className={styles.label}>
                      Name <br />{" "}
                      <input
                        placeholder="John Doe"
                        className={styles.input}
                        type="text"
                        name="name"
                        required
                      />
                    </label>
                  </p>
                  <p>
                    <label className={styles.label}>
                      Email
                      <br />{" "}
                      <input
                        placeholder="johndoe@example.com"
                        className={styles.input}
                        type="email"
                        name="email"
                        required
                      />
                    </label>
                  </p>
                  <p>
                    <label className={styles.label}>
                      Contact Number
                      <br />{" "}
                      <input
                        placeholder="+91 733 453 1058"
                        className={styles.input}
                        type="tel"
                        name="phone"
                        required
                      />
                    </label>
                  </p>
                  <p>
                    <label className={styles.label}>
                      Message <br />{" "}
                      <textarea
                        className={styles.textArea}
                        placeholder="Enter your message.."
                        name="message"
                        required
                        defaultValue={
                          "Hello Teca, I would love to receive a callback."
                        }
                      ></textarea>
                    </label>
                  </p>
                  <p>
                    <button type="submit">Submit</button>
                  </p>
                </form>
              </div>
              <div className={styles.right}>
                <iframe
                  title="iframe"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15597.149409703343!2d75.1680625!3d12.2288125!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba465d8cd052651%3A0x384613bca8fdc8b5!2sTeca%20Interiors!5e0!3m2!1sen!2sin!4v1687605679925!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className={styles.bottom}>
              <h1 className={styles.cardHeader}>
                Follow us on our Social Media to keep yourself updated✨
              </h1>
              <ul className={styles.socialCards}>
                <li className={styles.card}>
                  <a
                    className={styles.linkBlue}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/people/Teca-Interiors/100089351357978/"
                  >
                    <StaticImage
                      src="../images/contact/Facebook.svg"
                      alt="Facebook"
                      className={styles.social}
                    />
                    <p>FaceBook</p>
                  </a>
                </li>
                <li className={styles.card}>
                  <a
                    className={styles.linkGradient}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/teca_interiors/"
                  >
                    <StaticImage
                      src="../images/contact/Instagram.svg"
                      alt="Instagram"
                      className={styles.social}
                    />
                    <p>Instagram</p>
                  </a>
                </li>
                <li className={styles.card}>
                  <a
                    className={styles.linkGreen}
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/+918547461150"
                  >
                    <StaticImage
                      src="../images/contact/WhatsApp.svg"
                      alt="WhatsApp"
                      className={styles.social}
                    />
                    <p>WhatsApp</p>
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </Container>
      </div>
    </Layout>
  )
}

export default contact
